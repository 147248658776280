import headerLandingEn from '../layouts/UnauthorisedLayout/i18n/locales/en/headerLanding.json';
import headerLandingRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/headerLanding.json';

import menuLandingEn from '../layouts/UnauthorisedLayout/i18n/locales/en/menuLanding.json';
import menuLandingRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/menuLanding.json';

import landingPageEn from '../layouts/UnauthorisedLayout/i18n/locales/en/landingPage.json';
import landingPageRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/landingPage.json';

import heroSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/heroSection.json';
import heroSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/heroSection.json';

import ourWorksSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/ourWorksSection.json';
import ourWorksSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/ourWorksSection.json';

import workWithSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/workWithSection.json';
import workWithSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/workWithSection.json';

import howItWorksSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/howItWorksSection.json';
import howItWorksSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/howItWorksSection.json';

import advantagesSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/advantagesSection.json';
import advantagesSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/advantagesSection.json';

import whatYouGetSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/whatYouGetSection.json';
import whatYouGetSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/whatYouGetSection.json';

import tariffSelectorSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/tariffSelectorSection.json';
import tariffSelectorSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/tariffSelectorSection.json';

import faqSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/faqSection.json';
import faqSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/faqSection.json';

import startAiSectionEn from '../layouts/UnauthorisedLayout/i18n/locales/en/startAiSection.json';
import startAiSectionRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/startAiSection.json';

import footerLandingEn from '../layouts/UnauthorisedLayout/i18n/locales/en/footerLanding.json';
import footerLandingRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/footerLanding.json';

import chatButtonEn from '../layouts/UnauthorisedLayout/i18n/locales/en/chatButton.json';
import chatButtonRu from '../layouts/UnauthorisedLayout/i18n/locales/ru/chatButton.json';

import loginFormEn from '../modules/auth/i18n/locales/en/loginForm.json';
import loginFormRu from '../modules/auth/i18n/locales/ru/loginForm.json';

import signUpFormEn from '../modules/auth/i18n/locales/en/signUpForm.json';
import signUpFormRu from '../modules/auth/i18n/locales/ru/signUpForm.json';

import menuMainEn from '../layouts/MainLayout/i18n/locales/en/menuMain.json';
import menuMainRu from '../layouts/MainLayout/i18n/locales/ru/menuMain.json';

import offerInfoEn from '../layouts/MainLayout/i18n/locales/en/offerInfo.json';
import offerInfoRu from '../layouts/MainLayout/i18n/locales/ru/offerInfo.json';

import currentCompanyInfoEn from '../layouts/MainLayout/i18n/locales/en/currentCompanyInfo.json';
import currentCompanyInfoRu from '../layouts/MainLayout/i18n/locales/ru/currentCompanyInfo.json';

import accountPageEn from '../modules/account/i18n/locales/en/accountPage.json';
import accountPageRu from '../modules/account/i18n/locales/ru/accountPage.json';

import tariffPageEn from '../modules/tariff/i18n/locales/en/tariffPage.json';
import tariffPageRu from '../modules/tariff/i18n/locales/ru/tariffPage.json';

import companyCreatePageEn from '../modules/company/i18n/locales/en/companyCreatePage.json';
import companyCreatePageRu from '../modules/company/i18n/locales/ru/companyCreatePage.json';

import companyDetailsPageEn from '../modules/company/i18n/locales/en/companyDetailsPage.json';
import companyDetailsPageRu from '../modules/company/i18n/locales/ru/companyDetailsPage.json';

import companyUpdatePageEn from '../modules/company/i18n/locales/en/companyUpdatePage.json';
import companyUpdatePageRu from '../modules/company/i18n/locales/ru/companyUpdatePage.json';

import companyDeletePageEn from '../modules/company/i18n/locales/en/companyDeletePage.json';
import companyDeletePageRu from '../modules/company/i18n/locales/ru/companyDeletePage.json';

import productCreatePageEn from '../modules/product/i18n/locales/en/productCreatePage.json';
import productCreatePageRu from '../modules/product/i18n/locales/ru/productCreatePage.json';

import productUpdatePageEn from '../modules/product/i18n/locales/en/productUpdatePage.json';
import productUpdatePageRu from '../modules/product/i18n/locales/ru/productUpdatePage.json';

import productDeletePageEn from '../modules/product/i18n/locales/en/productDeletePage.json';
import productDeletePageRu from '../modules/product/i18n/locales/ru/productDeletePage.json';

import targetAudiencePageEn from '../modules/target-audience/i18n/locales/en/targetAudiencePage.json';
import targetAudiencePageRu from '../modules/target-audience/i18n/locales/ru/targetAudiencePage.json';

import targetAudienceUpdatePageEn from '../modules/target-audience/i18n/locales/en/targetAudienceUpdatePage.json';
import targetAudienceUpdatePageRu from '../modules/target-audience/i18n/locales/ru/targetAudienceUpdatePage.json';

import socialMediaAddPageEn from '../modules/social-media/i18n/locales/en/socialMediaAddPage.json';
import socialMediaAddPageRu from '../modules/social-media/i18n/locales/ru/socialMediaAddPage.json';

import ideaQueriesListPageEn from '../modules/idea-queries/i18n/locales/en/ideaQueriesListPage.json';
import ideaQueriesListPageRu from '../modules/idea-queries/i18n/locales/ru/ideaQueriesListPage.json';

import ideaQueriesCreatePageEn from '../modules/idea-queries/i18n/locales/en/ideaQueriesCreatePage.json';
import ideaQueriesCreatePageRu from '../modules/idea-queries/i18n/locales/ru/ideaQueriesCreatePage.json';

import ideaQueriesDetailsPageEn from '../modules/idea-queries/i18n/locales/en/ideaQueriesDetailsPage.json';
import ideaQueriesDetailsPageRu from '../modules/idea-queries/i18n/locales/ru/ideaQueriesDetailsPage.json';

import scenarioQueriesListPageEn from '../modules/scenario-queries/i18n/locales/en/scenarioQueriesListPage.json';
import scenarioQueriesListPageRu from '../modules/scenario-queries/i18n/locales/ru/scenarioQueriesListPage.json';

import scenarioQueriesCreatePageEn from '../modules/scenario-queries/i18n/locales/en/scenarioQueriesCreatePage.json';
import scenarioQueriesCreatePageRu from '../modules/scenario-queries/i18n/locales/ru/scenarioQueriesCreatePage.json';

import scenarioQueriesDetailsPageEn from '../modules/scenario-queries/i18n/locales/en/scenarioQueriesDetailsPage.json';
import scenarioQueriesDetailsPageRu from '../modules/scenario-queries/i18n/locales/ru/scenarioQueriesDetailsPage.json';

import postQueriesListPageEn from '../modules/post-query/i18n/locales/en/postQueriesListPage.json';
import postQueriesListPageRu from '../modules/post-query/i18n/locales/ru/postQueriesListPage.json';

import postQueryCreatePageEn from '../modules/post-query/i18n/locales/en/postQueryCreatePage.json';
import postQueryCreatePageRu from '../modules/post-query/i18n/locales/ru/postQueryCreatePage.json';

import postQueryDetailsPageEn from '../modules/post-query/i18n/locales/en/postQueryDetailsPage.json';
import postQueryDetailsPageRu from '../modules/post-query/i18n/locales/ru/postQueryDetailsPage.json';

import postDetailsPageEn from '../modules/post/i18n/locales/en/postDetailsPage.json';
import postDetailsPageRu from '../modules/post/i18n/locales/ru/postDetailsPage.json';

import postUpdatePageEn from '../modules/post/i18n/locales/en/postUpdatePage.json';
import postUpdatePageRu from '../modules/post/i18n/locales/ru/postUpdatePage.json';

import postDeletePageEn from '../modules/post/i18n/locales/en/postDeletePage.json';
import postDeletePageRu from '../modules/post/i18n/locales/ru/postDeletePage.json';

import contentPlanPageEn from '../modules/content-plan/i18n/locales/en/contentPlanPage.json';
import contentPlanPageRu from '../modules/content-plan/i18n/locales/ru/contentPlanPage.json';

const resources = {
  en: {
    translation: {
      // landing page:
      headerLanding: headerLandingEn,
      menuLanding: menuLandingEn,
      landingPage: landingPageEn,
      heroSection: heroSectionEn,
      ourWorksSection: ourWorksSectionEn,
      workWithSection: workWithSectionEn,
      howItWorksSection: howItWorksSectionEn,
      advantagesSection: advantagesSectionEn,
      whatYouGetSection: whatYouGetSectionEn,
      tariffSelectorSection: tariffSelectorSectionEn,
      faqSection: faqSectionEn,
      startAiSection: startAiSectionEn,
      footerLanding: footerLandingEn,
      chatButton: chatButtonEn,
      // MODULE auth:
      loginForm: loginFormEn,
      signUpForm: signUpFormEn,
      // main layout components:
      menuMain: menuMainEn,
      offerInfo: offerInfoEn,
      currentCompanyInfo: currentCompanyInfoEn,
      // MODULE account:
      accountPage: accountPageEn,
      // MODULE tariff:
      tariffPage: tariffPageEn,
      // MODULE company:
      companyCreatePage: companyCreatePageEn,
      companyDetailsPage: companyDetailsPageEn,
      companyUpdatePage: companyUpdatePageEn,
      companyDeletePage: companyDeletePageEn,
      // MODULE product:
      productCreatePage: productCreatePageEn,
      productUpdatePage: productUpdatePageEn,
      productDeletePage: productDeletePageEn,
      // MODULE target-audience:
      targetAudiencePage: targetAudiencePageEn,
      targetAudienceUpdatePage: targetAudienceUpdatePageEn,
      // MODULE social-media:
      socialMediaAddPage: socialMediaAddPageEn,
      // MODULE idea-queries:
      ideaQueriesListPage: ideaQueriesListPageEn,
      ideaQueriesCreatePage: ideaQueriesCreatePageEn,
      ideaQueriesDetailsPage: ideaQueriesDetailsPageEn,
      // MODULE scenario-queries:
      scenarioQueriesListPage: scenarioQueriesListPageEn,
      scenarioQueriesCreatePage: scenarioQueriesCreatePageEn,
      scenarioQueriesDetailsPage: scenarioQueriesDetailsPageEn,
      // MODULE post-query:
      postQueriesListPage: postQueriesListPageEn,
      postQueryCreatePage: postQueryCreatePageEn,
      postQueryDetailsPage: postQueryDetailsPageEn,
      // MODULE post:
      postDetailsPage: postDetailsPageEn,
      postUpdatePage: postUpdatePageEn,
      postDeletePage: postDeletePageEn,
      // MODULE content-plan:
      contentPlanPage: contentPlanPageEn
    },
  },
  ru: {
    translation: {
      // landing page:
      headerLanding: headerLandingRu,
      menuLanding: menuLandingRu,
      landingPage: landingPageRu,
      heroSection: heroSectionRu,
      ourWorksSection: ourWorksSectionRu,
      workWithSection: workWithSectionRu,
      howItWorksSection: howItWorksSectionRu,
      advantagesSection: advantagesSectionRu,
      whatYouGetSection: whatYouGetSectionRu,
      tariffSelectorSection: tariffSelectorSectionRu,
      faqSection: faqSectionRu,
      startAiSection: startAiSectionRu,
      footerLanding: footerLandingRu,
      chatButton: chatButtonRu,
      // MODULE auth:
      loginForm: loginFormRu,
      signUpForm: signUpFormRu,
      // main layout components:
      menuMain: menuMainRu,
      offerInfo: offerInfoRu,
      currentCompanyInfo: currentCompanyInfoRu,
      // MODULE account:
      accountPage: accountPageRu,
      // MODULE tariff:
      tariffPage: tariffPageRu,
      // MODULE company:
      companyCreatePage: companyCreatePageRu,
      companyDetailsPage: companyDetailsPageRu,
      companyUpdatePage: companyUpdatePageRu,
      companyDeletePage: companyDeletePageRu,
      // MODULE product:
      productCreatePage: productCreatePageRu,
      productUpdatePage: productUpdatePageRu,
      productDeletePage: productDeletePageRu,
      // MODULE target-audience:
      targetAudiencePage: targetAudiencePageRu,
      targetAudienceUpdatePage: targetAudienceUpdatePageRu,
      // MODULE social-media:
      socialMediaAddPage: socialMediaAddPageRu,
      // MODULE idea-queries:
      ideaQueriesListPage: ideaQueriesListPageRu,
      ideaQueriesCreatePage: ideaQueriesCreatePageRu,
      ideaQueriesDetailsPage: ideaQueriesDetailsPageRu,
      // MODULE scenario-queries:
      scenarioQueriesListPage: scenarioQueriesListPageRu,
      scenarioQueriesCreatePage: scenarioQueriesCreatePageRu,
      scenarioQueriesDetailsPage: scenarioQueriesDetailsPageRu,
      // MODULE post-query:
      postQueriesListPage: postQueriesListPageRu,
      postQueryCreatePage: postQueryCreatePageRu,
      postQueryDetailsPage: postQueryDetailsPageRu,
      // MODULE post:
      postDetailsPage: postDetailsPageRu,
      postUpdatePage: postUpdatePageRu,
      postDeletePage: postDeletePageRu,
      // MODULE content-plan:
      contentPlanPage: contentPlanPageRu
    },
  },
};

export default resources;
