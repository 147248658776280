import React from "react";
import styles from "../UnauthorisedLayout.module.scss";

export const AgreementPage = () => {
  return (
    <section className={styles.docsSection}>
      <h1 className={styles.docsSection__title}>Договор оферты</h1>
      <div className={styles.docsSection__text__flexed}>
        <div className={styles.docsSection__text__bold}>г. Астана«01»</div>
        <div className={styles.docsSection__text__bold}>мая 2024 г.</div>
      </div>
      <div className={styles.docsSection__text}>
        Настоящим ТОО «A-Gene», в лице директора Төкенұлы А., действующей на
        основании Устава, предлагает любому дееспособному физическому лицу
        подтвердить и принять к исполнению настоящие Общие условия купли-продажи
        услуг на условиях, изложенных в настоящем Договоре оферты (далее
        Договор). Настоящий Договор расположен в сети Интернет по адресу:
        www.aimmagic.com (далее - Сайт) и является публичной офертой (далее –
        Оферта).
      </div>

      <h3 className={styles.docsSection__subtitle}>1.Термины и определения</h3>
      <div className={styles.docsSection__text}>
        Для целей настоящего Договора нижеприведенные термины имеют следующие
        значения:
      </div>
      <div className={styles.docsSection__text}>Продавец - ТОО “A-Gene”</div>
      <div className={styles.docsSection__text}>
        Сайт - www.aimmagic.com право на использование, которого принадлежит
        Продавцу.
      </div>
      <div className={styles.docsSection__text}>
        Услуга — комплекс действий, произведённый одним лицом либо организацией
        по заказу другого, имеющий целью удовлетворение потребности последнего.
      </div>
      <div className={styles.docsSection__text}>
        Покупатель - физическое лицо (сторона по настоящему Договору) указанное
        в Заказе в качестве получателя Услуги, либо принявшее и оплатившее
        Услугу, либо оформившее Заказ с целью приобретения (покупки) выбранного
        из представленного на Сайте ассортимента Услуг для последующего
        использования исключительно для личных, семейных, домашних и иных нужд,
        не связанных с осуществлением предпринимательской деятельности.
      </div>
      <div className={styles.docsSection__text}>
        Заказ - в порядке и на условиях определенных в настоящем Договоре и/или
        на Сайте, направление Покупателем Продавцу запроса на покупку Услуги,
        выбранного из представленного на Сайте ассортимента. Оформление
        Покупателем Услуги является подтверждением (акцептом) заключения
        настоящего Договора, ознакомления и принятия без оговорок всех условий
        настоящего Договора.
      </div>

      <h3 className={styles.docsSection__subtitle}>2.Общие положения</h3>
      <div className={styles.docsSection__text}>
        2.1 Настоящий Договор, а также информация (в том числе об Услугах),
        представленная на Сайте, являются публичной офертой в соответствии с п.5
        ст. 395 Гражданского Кодекса Республики Казахстан.
      </div>
      <div className={styles.docsSection__text}>
        2.2 Отношения между Продавцом и Покупателем по настоящему Договору
        регулируются действующим законодательством Республики Казахстан, в том
        числе положениями Гражданского кодекса РК, Закона РК «О защите прав
        потребителей», Закона РК «О персональных данных и их защите» и т.д.
      </div>
      <div className={styles.docsSection__text}>
        2.3 Стороны заключают настоящий Договор о реализации Продавцом Услуги
        Покупателю на условиях, определенных в настоящем Договоре.
      </div>
      <div className={styles.docsSection__text}>
        2.4 Моментом заключения настоящего Договора является подтверждение
        Продавцом возможности исполнения Заказа Покупателя.
      </div>
      <div className={styles.docsSection__text}>
        2.5 Договор действует до полного исполнения Сторонами принятых на себя
        обязательств, либо до момента досрочного расторжения Договора по
        инициативе одной из Сторон в соответствии с условиями настоящего
        Договора.
      </div>
      <div className={styles.docsSection__text}>
        2.6 Продавец оставляет за собой право в одностороннем порядке по своему
        усмотрению вносить изменения в настоящий Договор, в связи с чем
        Покупатель обязуется перед каждым оформлением Заказа ознакомиться с
        условиями настоящего Договора.
      </div>

      <h3 className={styles.docsSection__subtitle}>
        3. Порядок оформления заказа
      </h3>
      <div className={styles.docsSection__text}>
        3.1 Покупатель выбирает понравившийся Услугу из числа, представленного
        на Сайте ассортимента.
      </div>
      <div className={styles.docsSection__text}>
        3.2 Покупатель самостоятельно несёт ответственность за содержание и
        достоверность представляемой информации.
      </div>
      <div className={styles.docsSection__text}>
        3.3 Продавец не несет ответственности за неисполнение или ненадлежащее
        исполнение настоящего Договора, в том числе Заказа, в случае указания
        Покупателем при оформлении Заказа неполной или недостоверной информации.
      </div>
      <div className={styles.docsSection__text}>
        3.4 Покупатель обязуется не сообщать третьим лицам логин и пароль,
        присвоенные при оформлении Заказа (регистрации на Сайте).
      </div>

      <h3 className={styles.docsSection__subtitle}>
        4. Порядок исполнения заказа
      </h3>
      <div className={styles.docsSection__text}>
        4.1 В целях исполнения своих обязательств по предаставлению Услуги
        Продавец может использовать услуги третьих лиц.
      </div>
      <div className={styles.docsSection__text}>
        4.2 В соответствии c законом Республики Казахстан «О персональных данных
        и их защите» Покупатель для целей исполнения Договора, дает Продавцу
        согласие на обработку предоставленных Продавцу в связи с Договором своих
        персональных данных: фамилии, имени, отчества, год рождения, номера
        телефона, специлизацию, номера телефонов, адрес электронной почти и
        другой информации.
      </div>
      <div className={styles.docsSection__text}>
        4.3 Обработка персональных данных включает следующие действия: сбор,
        запись, систематизацию, накопление, хранение, уточнение (обновление,
        изменение), использование, передачу, распространение, обезличивание,
        блокирование, удаление, уничтожение, персональных данных.
      </div>
      <div className={styles.docsSection__text}>
        4.4 Продавец вправе в необходимом объеме раскрывать с целью исполнения
        Договора персональные данные Покупателя третьи лицам.
      </div>
      <div className={styles.docsSection__text}>
        4.5 Обработка персональных данных Покупателя может быть осуществлена
        третьим лицом на основании договора с Продавцом. Покупатель также дает
        Продавцу разрешение на обработку персональных данных методом смещанной
        (в том числе и автоматизированной) обработки, систематизацию, хранение,
        распространение и передачу с использованием сети Интернет третьим лицам.
      </div>
      <div className={styles.docsSection__text}>
        4.6 Продавец обязуется не использовать персональные данные Покупателя
        (получателя) в целях, не предусмотренных настоящим Договором, и
        обеспечить конфиденциальность персональных данных о Покупателе. Условия
        об использовании предоставленных Покупателем персональных данных, а
        также условия использования Покупателями материалов и сервисов Сайта
        изложены в разделе “Политика конфиденциальности”, размещенном на Сайте и
        являющемся неотъемлемой частью настоящего Договора.
      </div>

      <h3 className={styles.docsSection__subtitle}>5. Оплата услуги</h3>
      <div className={styles.docsSection__text}>
        5.1 Цены на Услуги, которые могут быть заказаны Покупателем, указаны на
        Сайте и переводятся в национальную валюту (казахстанский тенге) по курсу
        на день покупки.
      </div>
      <div className={styles.docsSection__text}>
        5.2 Цены на Услуги, указанные на Сайте могут быть изменены Продавцом в
        одностороннем порядке. В случае несогласия Покупателя с предложенными
        Продавцом ценами Покупатель вправе в одностороннем порядке отказаться от
        исполнения настоящего Договора (аннулировать Заказ).
      </div>
      <div className={styles.docsSection__text}>
        5.3 Цены на Услуги, согласованные на момент заключения Договора
        (подтверждение Заказа Продавцом), не могут быть изменены Продавцом в
        одностороннем порядке, кроме случаев просрочки оплаты Покупателем данных
        Услуг.
      </div>
      <div className={styles.docsSection__text}>
        5.4 Покупатель оплачивает Услуги на условиях 100% предоплаты, но не
        позднее 2-х рабочих дней с момента заключения настоящего Договора
        (подтверждения Заказа) одним из доступных для соответствующего региона
        доставки способов, указанных на Сайте в разделе “Оплата”. Способ оплаты
        выбирается Покупателем при оформлении Заказа.
      </div>
      <div className={styles.docsSection__text}>
        5.5 Моментом исполнения Покупателем обязательства об оплате заказанной
        Услуги является момент зачисления денежных средств на расчетный счет
        Продавца.
      </div>
      <div className={styles.docsSection__text}>
        5.6 Возврат Продавцом, уплаченных Покупателем с использованием
        банковской карты денежных средств, в случаях, предусмотренных настоящим
        Договором, осуществляется на ту же банковскую карту, с которой
        осуществлялся платеж.
      </div>

      <h3 className={styles.docsSection__subtitle}>6. Порядок возврата</h3>
      <div className={styles.docsSection__text}>
        6.1 Покупатель вправе отказаться (возвратить Продавцу) от заказанной
        Услуги в любое время до его использования. После использования
        Покупатель имеет право вернуть часть денежных средств, соответствующей
        части не использованной Услуге.
      </div>
      <div className={styles.docsSection__text}>
        6.2 Покупатель, заключая настоящий Договор, имеет все права,
        предоставленные ему законодательством РК «О защите прав потребителей».
      </div>
      <div className={styles.docsSection__text}>
        6.3 Для осуществления возврата денежных средств Покупателем Продавцом
        должны быть соблюдены все условия настоящего Договора. Возврат денежных
        средств Покупателю осуществляется в зависимости от того, каким способом
        была произведена оплата. Если оплата была произведена безналичными
        денежными средствами, то возврат осуществляется на ту же карту, с
        которой были списаны денежные средства.
      </div>

      <h3 className={styles.docsSection__subtitle}>
        7. Интеллектуальная собственность
      </h3>
      <div className={styles.docsSection__text}>
        7.1. Покупатель согласен с тем, что исключительные права на
        интеллектуальную деятельность и средства индивидуализации (изображения,
        рисунки, чертежи, графические элементы, логотипы, слоганы), содержащиеся
        в представленной на Сайте информации, материалах, в том числе
        фотографиях, изображениях принадлежат Продавцу и/или иным партнерам
        Продавца, кроме тех, которые были созданы Покупателем во время
        использования Сайта и Услуг.
      </div>
      <div className={styles.docsSection__text}>
        7.2 В случае, если в период действия Договора возникнут новые или любые
        иные охранямые законом результаты интеллектуальной деятельности и/или
        приравненные к ним средства индивидуализации, любые исключительные права
        на них будут принадлежать Продавцу и/или иным партнерам Продавца, кроме
        тех, которые были созданы Покупателем во время использования Сайта и
        Услуг.
      </div>

      <h3 className={styles.docsSection__subtitle}>
        8.Форс-мажорные обстоятельства
      </h3>
      <div className={styles.docsSection__text}>
        8.1 Стороны не несут ответственности за неисполнение или ненадлежащее
        исполнение обязательств по настоящему Договору, а также за ущерб,
        причиненный вследствие наступления обстоятельств непреодолимой силы,
        вызванных прямо или косвенно проявлением землетрясений, пожаров, селей,
        наводнений, эпидемий, военных конфликтов или переворотов,
        террористических актов, гражданских волнений и забастовок, а также иных
        обстоятельств вне разумного контроля Сторон. Сроки выполнения этих
        обязательств соразмерно сдвигаются на время действия таких
        обстоятельств, если они значительно влияют на выполнение в срок взятых
        на себя обстоятельств в полном объеме или той их части, которая подлежит
        выполнению после наступления обстоятельств форс-мажора.
      </div>

      <h3 className={styles.docsSection__subtitle}>
        9. Ответственность сторон и разрешение споров
      </h3>
      <div className={styles.docsSection__text}>
        9.1 В случае неисполнения или ненадлежащего исполнения обязательств по
        Договору, Стороны несут ответственность в соответствии с действующим
        законодательством Республики Казахстан.
      </div>
      <div className={styles.docsSection__text}>
        9.2 Стороны примут все меры для урегулирования всех споров и
        разногласий, которые могут возникнуть при выполнении настоящего Договора
        или в связи с ним, путем переговоров между Сторонами.
      </div>
      <div className={styles.docsSection__text}>
        9.3 В случае, если споры и разногласия не могут быть решены путем
        переговоров, они должны быть рассмотрены в соответствии с действующим
        законодательством Республики Казахстан.
      </div>
      <div className={styles.docsSection__text}>
        9.4 Качество каналов связи общего пользования, с помощью которых
        осуществляется доступ к Сайту, находится вне границ ответственности
        сторон данного Договора.
      </div>

      <h3 className={styles.docsSection__subtitle}>10. Прочие условия</h3>
      <div className={styles.docsSection__text}>
        10.1 Настоящим Договором Покупатель выражает свое согласие на получение
        от Продавца любым из указанных Покупателем при оформлении Заказа
        способом маркетинговых или рекламных материалов, направленных на
        продвижение Услуг, в том числе и предполагающих вложение в Заказы.
      </div>
      <div className={styles.docsSection__text}>
        10.2 Под рабочими днями в целях настоящего Договора понимаются рабочие
        дни, исходя из пятидневной рабочей недели, кроме установленных
        законодательством выходных и нерабочих праздничных дней.
      </div>
      <div className={styles.docsSection__text}>
        10.3 Стороны договариваются, что признают юридическую силу текстов
        документов, полученных по каналам связи (веб-сайте, электронной почты и
        др.) наравне с документами, исполненными в простой письменной форме на
        бумажном носителе.
      </div>
      <div className={styles.docsSection__text}>
        10.4 Стороны соглашаются с тем, что вся переписка, извещения и
        уведомления, полученные на адреса электронной почты и/или веб-сайте,
        указанные в настоящем Договоре, считаются доставленными адресату в
        надлежащей форме.
      </div>
      <div className={styles.docsSection__text}>
        10.5 Настоящий Договор вступает в силу с момента Акцепта оферты
        Покупателем и заканчивается после исполнения принятых Сторонами на себя
        обязательств в соответствии с условиями Договора.
      </div>

      <h3 className={styles.docsSection__subtitle}>
        11. Регистрационные данные Продавца
      </h3>
      <ul className={styles.docsSection__list}>
        <li className={styles.docsSection__list__item}>
          Полное наименование организации:
        </li>
        <li className={styles.docsSection__list__item}>
          Товарищество с ограниченной ответственностью «A-Gene»
        </li>
        <li className={styles.docsSection__list__item}>Юридический адрес:</li>
        <li className={styles.docsSection__list__item}>
          Казахстан, г.Астана, ул. Сыганак 47
        </li>
        <li className={styles.docsSection__list__item}>БИН 190740005934</li>
        <li className={styles.docsSection__list__item}>
          Банковские реквизиты:
        </li>
        <li className={styles.docsSection__list__item}>
          АО «Народный Банк Казахстан»
        </li>
        <li className={styles.docsSection__list__item}>
          ИИК KZ53601A871028765411
        </li>
        <li className={styles.docsSection__list__item}>БИК HSBKKZKX</li>
      </ul>
    </section>
  );
};
